// Variable overrides

$theme: #eee;
$primary: #ffcc33;
$secondary: #d09c00;
$default: #e0e0e0;
$white: #fff;
$info: #3d58c3;
$link: #8800ff;
$danger: #dd001f;

$linkColor: #8800ff;
$borderGrey: #e0e0e0;
$bgGrey: #f6f6f6;
$darkGray: #24272c;
$bgDark: #01091e;
$darkBlue: #011851;
