// Here you can add other styles
.form-control.is-invalid {
	background-image: none; // remove cross image
}

.auth__header {
	display: flex;
	align-items: center;
	margin: 1rem 4rem;

	img {
		width: 2.5rem;
	}

	&__seperator {
		margin: 1rem;
	}

	&__cms--text {
		font-weight: 500;
	}
}

.cover {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
}

.visually-hidden {
	opacity: 0;
	width: 0;
	height: 0;
	overflow: hidden;
	padding: 0;
	margin: 0;
	border: 0;
	outline: none;
	position: absolute;
}

.number-input {
	padding-left: 50px;
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		/* display: none; <- Crashes Chrome on hover */
		-webkit-appearance: none;
		margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
	}

	&[type="number"] {
		-moz-appearance: textfield; /* Firefox */
	}
}

.tooltip-inner {
	max-width: 350px;
	/* If max-width does not work, try using width instead */
	width: 350px;
	font-size: 14px;
	div {
		text-align: left;
		margin-bottom: 5px;
		padding-left: 15px;
	}
}

.cursor-pointer {
	cursor: pointer;
}

.text-underlined {
	text-decoration: underline;
}

.section-header {
	font-weight: bold;
	font-size: 16px;
}
.custom-label {
	font-weight: bold;
	font-size: 14px;
}

.modal-form-label {
	font-weight: bold;
	font-size: 14px;
	padding-right: 0px;
	padding-left: 0px;
}

.search_icon {
	position: absolute;
	right: 20px;
	top: 20px;
}

.metricLabel {
	font-weight: bold;
	min-width: 200px;
}

.metricValue {
	font-weight: 900;
	font-size: 16px;
	max-width: 450px;
}

.display-block {
	display: block;
}

.autoComplete {
	top: 100%;
	background-color: #fff;
	border-radius: 4px;
	box-shadow: 0 0 0 1px #d4d4d4, 0 4px 11px #d4d4d4;
	position: absolute;
	margin-bottom: 8px;
	margin-top: 8px;
	position: absolute;
	z-index: 1;
	left: 15px;
	right: 15px;
	box-sizing: border-box;
	&__menu {
		max-height: 200px;
		box-sizing: border-box;
		overflow-y: auto;
		padding-top: 4px;
		padding-bottom: 4px;
	}
	&__option {
		background-color: transparent;
		color: inherit;
		font-weight: bold;
		display: block;
		cursor: pointer;
		padding: 8px 12px;
		width: 100%;
		border-bottom: 1px solid #d4d4d4;
	}

	&__selected {
		background-color: $theme;
	}

	&__option:hover {
		background-color: $theme;
	}
}

.w-70 {
	max-width: 70px;
}

.w-150 {
	max-width: 150px;
}

.w-200 {
	max-width: 200px;
}
