@import "variables";

body {
	background: #fff;
}

a,
a:hover,
a:visited,
a:active {
	color: $linkColor;
}
