@import "variables";

.getGoInput {
	border-radius: 5px;
	border: 1px solid $borderGrey;
	padding: 24px 40px 24px 20px;
	background: $white;
	color: #000;

	&[type="number"] {
		-moz-appearance: textfield;
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&--single-input {
		min-width: 320px;
	}
}

.getGoLabel {
	display: inline-block;
	font-weight: bold;
	padding-bottom: 10px;
}

.Autocomplete {
	&__menu {
		border-radius: 3px;
		box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
		background: rgba(255, 255, 255, 0.9);
	}
	&__item {
		background: white;
		padding: 10px;

		&:hover {
			background: #eee;
		}
	}
	&.item-highlighted {
		background: $primary;
		color: white;
	}
}
