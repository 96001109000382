@import "variables";

.themeButton {
	border-radius: 24px;
	padding: 10px 15px;
	font-weight: bold;
	border: 0;

	min-width: 120px;

	&:disabled {
		background: #dce0e0;
		pointer-events: none;
	}

	&--primary {
		background: $primary;
		color: $theme;

		&:hover {
			background: darken($primary, 10%);
			color: $white;
		}
	}

	&--blue {
		background: $darkBlue;
		color: #fff;

		&:hover {
			background: darken($darkBlue, 10%);
			color: #ccc;
		}
	}

	&--secondary {
		color: $secondary;
		background: $white;
		border: 1px solid $secondary;

		&:hover {
			background: $secondary;
			color: $white;
			border: 1px solid $white;
		}
	}
}

.cursor-pointer {
	cursor: pointer;
}
