@import "variables";

.text-dark-gray {
	color: $darkGray;
}

.text-info {
	color: $info;
}

.text-link {
	color: $link;
}

.text-danger {
	color: $danger;
}

.bg-gray {
	background: $bgGrey;
}

.bg-theme {
	background: $theme;
}

.bg-dark {
	background: $bgDark;
}

.bg-semi-transparent {
	background: rgba(255, 255, 255, 0.8);

	&--black {
		background: rgba(0, 0, 0, 0.8);
	}
}
