//generic reusable animations

@charset 'utf-8';

@keyframes shake {
  0% {
    transform: translateX(0px);
  }

  12.5% {
    transform: translateX(-12px);
  }

  37.5% {
    transform: translateX(12px);
  }

  50% {
    transform: translateX(0px);
  }

  62.5% {
    transform: translateX(-5px);
  }

  87.5% {
    transform: translateX(5px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes from-top {
  0% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes from-bottom {
  0% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes from-left {
  0% {
    transform: translateX(-20px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes from-right {
  0% {
    transform: translateX(20px);
  }

  100% {
    transform: translateX(0px);
  }
}

@for $i from 1 through 10 {
  .animated-delay--#{str-replace($i / 2+"", ".", "-")} {
    animation-delay: $i / 20 + s !important;
  }

  .transition-delay--#{str-replace($i / 2+"", ".", "-")} {
    transition-delay: $i / 20 + s !important;
  }
}

.animation-workaround {
  animation: shake .1s, fade-in .1s, fade-out .1s, from-top .1s, from-bottom .1s, from-left .1s, from-right .1s;
}
