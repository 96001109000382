@import "variables";

.theme-color {
	color: $theme;

	&:hover {
		color: $theme;
	}
}

.large-3x {
	font-size: 1.6rem;
}

.large-2x {
	font-size: 1.3rem;
}

.large-1x {
	font-size: 1.1rem;
}
